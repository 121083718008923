/* Cards */

.card {
  box-shadow: $card-box-shadow;
  -webkit-box-shadow: $card-box-shadow;
  -moz-box-shadow: $card-box-shadow;
  -ms-box-shadow: $card-box-shadow;
  background: none;
  border: none;
  .card-body {
    padding: $card-padding-y $card-padding-x;
  }
  &.card-hover {
    transition: box-shadow 0.25s ease, transform 0.25s ease;
    &:hover {
      box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1);
      transform: translate3d(0, -3px, 0);
    }
  }
}
