@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Light.eot");
  src: url("../fonts/Montserrat/Montserrat-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Light.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-Light.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Bold.eot");
  src: url("../fonts/Montserrat/Montserrat-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-Bold.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Medium.eot");
  src: url("../fonts/Montserrat/Montserrat-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Medium.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-Medium.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Regular.eot");
  src: url("../fonts/Montserrat/Montserrat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Regular.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-Regular.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Black.eot");
  src: url("../fonts/Montserrat/Montserrat-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Black.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-Black.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
