.banner-top-thumb-wrap {
  padding: 22px 0;
  border-bottom: 1px solid $border-color;
  margin-bottom: 50px;
}
.banner-top-thumb {
  width: 82px;
  height: 42px;
  margin-right: 10px;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.img-xs {
  width: 14px;
  height: 14px;
}
.img-lg {
  width: 74px;
  height: 74px;
}
.img-rounded {
  border-radius: 100%;
}
.line-height-sm {
  line-height: 1.29;
}
#main-banner-carousel {
  .carousel-content-wrapper {
    position: relative;
    .carousel-content {
      width: 75%;
      position: absolute;
      left: 40px;
      bottom: 40px;
      color: $white;
      @media (max-width: 999px) {
        bottom: 12px;
        h1 {
          font-size: 16px;
        }
        h5 {
          font-size: 14px;
        }
      }
    }
  }
  .owl-dots {
    counter-reset: dots;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 50px 0;
    @media (max-width: 1024px) {
      padding: 10px 0;
    }
    @media (max-width: 767px) {
      padding: 10px 0;
    }
    .owl-dot {
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      padding: 14px 16px 14px 25px !important; // to override padding of carousel dot
      margin: 10px 0;
      border-radius: 96px 0 0px 96px;
      color: $white;
      &.active {
        background: theme-color(primary);
      }
      @media (max-width: 767px) {
        padding: 8px 12px 8px 20px !important; // to override padding of carousel dot responsive
        margin: 0;
      }
    }
    span {
      display: none;
    }
  }
  .owl-dot:before {
    counter-increment: dots;
    content: counter(dots);
  }
}
.world-news,
.popular-news {
  padding: 37px 0;
}
.font-weight-600 {
  font-weight: 600;
}
.section-title {
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: theme-color(success);
  margin-bottom: 22px;
  &:after {
    content: "";
    width: 50px;
    height: 2px;
    background: theme-color(success);
    position: absolute;
    right: -60px;
    top: 14px;
  }
}
.thumb-title {
  padding: 14px 24px;
  background: theme-color(dark);
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $white;
  position: absolute;
  bottom: 0;
  left: 0;
}
.video-thumb {
  position: absolute;
  left: 24px;
  bottom: 24px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  span {
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 100%;
    border: 1px solid $border-color;
    font-size: 14px;
    margin-right: 6px;
  }
}
.contact-wrap {
  width: 920px;
  margin: 0 auto;
  @media (max-width: 999px) {
    width: 100%;
  }
}
.image-hover {
  overflow: hidden;
  img,
  .thumb-title {
    transition: transform 0.4s; /* Animation */
  }
  &:hover {
    img {
      transform: scale(1.25);
    }
    .thumb-title {
      background: theme-color(primary);
    }
  }
}
